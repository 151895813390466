<template>
<section class="hero is-fullheight" style="background-image: url('https://res.cloudinary.com/celestialmk/image/upload/v1726132905/ppwac00fp2xkbzkd9n6a.jpg'); background-size: cover; background-position: center;">
  <div class="hero-body is-flex is-justify-content-center is-align-items-center">
    <div class="container">
        <h1 class="title is-1 has-text-white has-text-weight-bold" style="font-size: 70px;">
          KIGEZI CEMENT PROJECT
        </h1>
        <h2 class="subtitle has-text-white has-text-weight-bold" style="font-size: 24px; margin-top: 64px;">
            For a Sustainable and Stronger Tomorrow
        </h2>
    </div>
  </div>
</section>
<section class="hero is-fullheight has-background-blue-light section-transition">
    <div  class="hero-body is-justify-content-center is-align-items-center">
        <div class="container has-text-left">
            <h1 data-aos="fade-up" class="title is-2 has-text-center has-text-black" style="font-size: 38px;" data-aos-once="true">
                Kigezi Cement Project Background
            </h1>

            <div data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
              <p class="mb-4 custom-font-size">
                  This project is aimed at producing construction materials using cement manufactured from volcanic ash and kaolinite clay. These minerals are abundant in Uganda especially in the western region. 
              </p>
              <p class="mb-4 custom-font-size">
                  Cement is a significant component of housing construction. Its content in buildings ranges from 5% for small units to 50% for large housing units depending on the design and architecture and accounts for over 60% of the total project concrete cost. 
                  Uganda has a growing construction industry averaging 15.5% per year since 2005. According to UBOS, approximately 8.3 million people, representing about 21% of Uganda’s population, live in inadequate housing conditions creating a housing deficit of 2.4 million housing units. 
              </p>
              <p class="mb-4 custom-font-size">
                  The high cost of cement makes construction expensive and unattainable for Uganda’s population living below the poverty line. Additionally, the use of cement in construction escalates Uganda’s carbon dioxide footprint. Portland Cement alone is responsible for over 8% of global carbon dioxide emissions. 
              </p>
  
              <p class="mb-4 custom-font-size">
                  Kigezi cement provides a cheaper and sustainable alternative to cater for the country’s Infrastructure needs while drawing less from the environment. 
              </p>
            </div>
        </div>
    </div>
</section>
<section class="hero is-fullheight is-light">
  <div class="hero-body">
    <div class="container">
      <div class="columns is-vcentered">
        <!-- First column with the image -->
        <div class="column is-flex is-justify-content-center">
          <figure class="image" style="width: 244px; height: 307px; object-fit: cover;">
            <img src="https://res.cloudinary.com/celestialmk/image/upload/v1725963784/tns1oznjyz3e5vsej6fo.png" alt="Kigezi cement image" style="border-radius: 10px;">
          </figure>
        </div>

        <!-- Second column with title and paragraphs -->
        <div class="column">
          <h1 data-aos="fade-up" data-aos-delay="" class="title has-text-left" style="font-size: 38px;" data-aos-once="true">
            Kigezi Cement
          </h1>
          <div data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
            <p  class="mb-4 has-text-left has-text-weight-bold" style="font-size: 20px;">
              Made from naturally occurring minerals like volcanic ash, clay ,  limestone and waste materials such as blast furnace slag, fly ash, and rice husks.
            </p>
            <p class="mb-5 has-text-left" style="font-size: 20px;">
              These materials are ground and mixed with activators such as lime and alkali solutions to form environmentally friendly binders that can be used as cement for all construction purposes.
            </p>
            <p class="mb-4 has-text-left has-text-weight-bold" style="font-size: 20px;">
              This cement manufacturing process eliminates the high energy requirement and consequent carbon dioxide emissions from the manufacture of traditional cements. This makes cement cheaper and environmentally  friendly. 
            </p>
            <p class="mb-4 has-text-left" style="font-size: 20px;">
              Incorporating waste materials reduces  the need for non renewable materials, which helps to conserve natural resources and reduces waste.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="hero is-fullheight is-white">
  <div class="hero-body">
    <div class="container">
      <div class="columns is-vcentered">
          <!-- Second column with title and paragraphs -->
          <div class="column content">
            <h1 data-aos="fade-up" class="title has-text-left" data-aos-once="true" style="font-size: 38px;">
                Kigezi Cement types and Recipes 
            </h1>

            <ol class="has-text-left" style="font-size: 20px;" data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
            <li class="mb-4">
                Geopolymer Cement <br>
                Made from aluminosilicates (volcanic ash and kaolinite clay)  and alkali activators 
            </li>
            <li class="mb-4">
                VA-LG Cement <br>
                Made from Volcanic Ash, Lime and Gypsum 
            </li>
            <li class="mb-4">
                LC3 <br>
                A hybrid cement made from Limestone, calcined clay and clinker
            </li>
            <li class="mb-4">
                LC2 <br>
                Made from Lime stone and calcined clay 
            </li>
            </ol>
          </div>
        <!-- First column with the image -->
        <div class="column is-flex is-justify-content-center">
          <figure class="image is1by1">
            <img src="https://res.cloudinary.com/celestialmk/image/upload/v1725966077/ht6iowgkplurrg0l9bge.png" alt="Kigezi cement image" style="border-radius: 10px;">
          </figure>
        </div>

      </div>
    </div>
  </div>
</section>

<section class="hero is-medium is-light">
  <div class="hero-body">
    <div class="container">
      <div class="columns is-vcentered">
        <!-- First column with the image -->
        <div class="column is-flex is-justify-content-center">
          <figure class="image is16by9">
            <img src="https://res.cloudinary.com/celestialmk/image/upload/v1727518572/i9gvfi8f5ggm4e8mrp35.jpg" alt="Kigezi cement image" style="border-radius: 10px;">
          </figure>
        </div>

        <!-- Second column with title and paragraphs -->
        <div class="column content">
          <h1 data-aos="fade-up" data-aos-delay="" class="title has-text-left" style="font-size: 38px;" data-aos-once="true">
            Recipes and Strength Potential
          </h1>
         
              <ul data-aos="fade-up" data-aos-delay="100" data-aos-once="true" class="has-text-left content" style="font-size: 20px;">
                <li class="mb-5">
                    Adequate for both low strength and high strength concrete applications with strength ranging from 4MPa to 70MPa.
                </li>
                <li class="mb-5">
                    Offer greater chloride ion resistance
                </li>
                <li class="mb-5">
                    Offer better water resistance properties
                </li>
              </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="hero is-medium">
    <div class="hero-body is-justify-content-center is-align-items-center">
        <div class="container has-text-left">
            <h1 data-aos="fade-up" data-aos-delay="" data-aos-once="true" class="title is-2 has-text-center has-text-black" style="font-size: 38px;">
                Contribution to Sustainable Development Goals
            </h1>
 
            <figure data-aos="fade-up" data-aos-delay="100" data-aos-once="true" class="image is-16by9 my-image">
                <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726072476/bbdh0di9yih04wtkl2lv.jpg" alt="SDG image" style="border-radius: 10px;" />
            </figure>
        </div>
    </div>
</section>

<section class="hero is-fullheight is-light">
    <div class="hero-body is-justify-content-center is-align-items-center">
        <div class="container has-text-left">
            <h1 data-aos="fade-up" data-aos-delay="" data-aos-once="true" class="title is-2 has-text-center has-text-black" style="font-size: 38px;">
                Our Partners
            </h1>
            <div class="content" data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
                <ul>

                </ul>
                <li class="mb-5 custom-font-size">
                    DFCD coordinated by WWF Uganda: Funder
                </li>
                <li class="mb-5 custom-font-size">
                    European Union through INNOWWIDE and the Eureka Network
                </li>
                <li class="mb-5 custom-font-size">
                    Eco Concrete Ltd: Implementer
                </li>
    
                <li class="mb-5 custom-font-size">
                    Universities with vibrant research in concrete  technology such as Makerere University: control testing  and knowledge sharing
                </li>
                <li class="mb-5 custom-font-size">
                    Concreet Projectmanagement B.V, Netherlands:  Suppliers of	activators and Partners
                </li>
                <li class="mb-5 custom-font-size">
                    UNABCEC: brings together civil contractors who are target users of Kigezi  Cement Products.
                </li>
            </div>
        </div>
    </div>
</section>

<section class="hero is-medium">
    <div class="hero-body is-justify-content-center is-align-items-center">
        <div class="container has-text-left">
            <h1 data-aos="fade-up" data-aos-delay="" data-aos-once="true" class="title is-2 has-text-center has-text-black" style="font-size: 38px;">
                Key Achievements
            </h1>

            <p data-aos="fade-up" data-aos-delay="100" data-aos-once="true" class="mb-4 custom-font-size">
                Acquisition of exploration licenses for plots in Rakai, Kisoro and Kasese  containing Volcanic Ash, Kaolin, Base metals  and Iron Ore under the Ministry of Energy and Mineral Development
            </p>

            <figure data-aos="fade-up" data-aos-delay="200" data-aos-once="true" class="image is16by9">
            <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726057625/qwvn29hf7nazeudf4q0s.jpg" alt="Location of all licenses" style="border-radius: 10px;">
          </figure>


        </div>
    </div>
</section>
<section class="hero is-medium is-light">
    <div class="hero-body is-justify-content-center is-align-items-center">
        <div class="container has-text-left">
            <p class="mb-4 custom-font-size" data-aos="fade-up" data-aos-once="true" data-aos-delay="">
                Acquisition of exploration licenses for plots in Rakai, Kisoro and Kasese  containing Volcanic Ash, Kaolin, Base metals  and Iron Ore under the Ministry of Energy and Mineral Development
            </p>

            <div class="columns is-vcentered">
                <div class="column is-flex is-justify-content-center">

                    <figure class="image is1by1" data-aos="fade-up" data-aos-once="true" data-aos-delay="100">
                    <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726058147/kmmtpcrsahwtrse07c4k.jpg" alt="Aquisition of 100 acres of land in Rubanda district" style="border-radius: 10px;">
                    </figure>
                </div>
                <div class="column">
                    <div class="columns is-mobile">
                        <div class="column">
                            <figure class="image is1by1" data-aos="fade-up" data-aos-once="true" data-aos-delay="100">
                            <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726057829/mxhdjdg88ut0tlrobx6n.jpg" alt="Aquisition of 100 acres of land in Rubanda district" style="border-radius: 10px;">
                            </figure>
                        </div>
                        <div class="column">
                            <figure class="image is1by1" data-aos="fade-up" data-aos-once="true" data-aos-delay="100">
                            <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726057997/tpjwc34wtkrby3ufjz4c.jpg" alt="Aquisition of 100 acres of land in Rubanda district" style="border-radius: 10px;">
                            </figure>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    </div>
</section>
<section class="hero is-medium">
    <div class="hero-body is-justify-content-center is-align-items-center">
        <div class="container has-text-left">
          <div class="columns is-vcentered">
            <div class="column is-flex is-justify-content-center">
              <figure class="image is16by9">
                <img src="https://res.cloudinary.com/celestialmk/image/upload/c_fill,w_1280,h_960/v1727518422/iul24czqlhthrbvzlqtn.jpg" alt="Kigezi cement community engagement" style="border-radius: 10px;">
              </figure>
            </div>

                    <!-- Second column with title and paragraphs -->
        <div class="column content">
          <h1 data-aos="fade-up" data-aos-delay="" class="title has-text-left" style="font-size: 38px;" data-aos-once="true">
            Community Engagement
          </h1>
         
          <p class="mb-4 custom-font-size" data-aos="fade-up" data-aos-once="true" data-aos-delay="100">
                Strong community relationships are essential for the success of this project. We aim to understand the community's priorities and address their concerns.
            </p>
        </div>

          </div>
        </div>
    </div>
</section>
<section class="hero is-fullheight is-light">
    <div class="hero-body is-justify-content-center is-align-items-center">
        <div class="container has-text-left">
            <h1 class="title is-2 has-text-center has-text-black" style="font-size: 38px;" data-aos="fade-up" data-aos-delay="" data-aos-once="true">
                Pilot production of cement products
            </h1>

            <div class="tile is-ancestor">
                <div class="tile is-parent"> 
                    <div class="content tile is-child"> 
                        <figure class="image is-4by3 " data-aos="fade-up" data-aos-delay="50" data-aos-once="true">
                            <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726074826/lbn716prhh85thlltb1b.jpg" alt="Pilot production of cement products" style="border-radius: 10px;">
                        </figure>
                    </div> 
                </div>
                <div class="tile is-vertical is-8">
                    <div class="tile is-parent">
                        <div class="tile is-child is-flex is-justify-content-center">
                            <figure class="image" style="height: auto; width: auto;" data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
                            <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726057455/uqop8wee1ean9ujqm8fb.jpg" alt="Pilot production of cement products" style="border-radius: 10px;">
                            </figure>
                        </div> 
                    </div> 
                    <div class="tile">
                        <div class="tile is-parent">
                            <div class="tile is-child ">
                                <figure class="image is-1by1" data-aos="fade-up" data-aos-delay="150" data-aos-once="true">
                                <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726057456/kwhecizsel3jxybeyqvv.jpg" alt="Pilot production of cement products" style="max-width: 300px; height: auto; border-radius: 10px;" class="pr-3">
                                </figure>
                            </div>
                            <div class="tile is-child">
                                <figure class="image is-1by1" data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
                                <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726057493/c9lqu2gytz11ruguxtpn.jpg" alt="Pilot production of cement products" style="max-width: 300px; height: auto; border-radius: 10px;" >
                                </figure>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div class="tile is-ancestor">
                <div class="tile is-parent"> 
                   
                    <div class="content tile is-child">
                        <div class="tile is-child">
                                <figure class="image is-1by1" style="max-width: 300px; height: auto;" data-aos="fade-up" data-aos-delay="250" data-aos-once="true">
                                <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726059819/le0vwc9dx4xgukrgkwgu.jpg" alt="Pilot production of cement products" style="border-radius: 10px;">
                                </figure>
                            </div>
                        
            
            
                    </div> 
    
                </div>
                <div class="tile is-vertical is-8">
                    <div class="tile is-parent">
                        <div class="tile is-child">
                            <figure class="image is-16by9" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
                            <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726059821/tsmgr4kmkjyoxwxonfgl.jpg" alt="Pilot production of cement products" style="border-radius: 10px;" >
                            </figure>
                        </div> 
                    </div> 
                    <div class="tile">
                        <div class="tile is-parent">
                            <div class="tile is-child">
                                <figure class="image rotate-90 is-1by1" style="max-width: 300px;">
                                <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726059819/glwlyqegixrx3ebpjcdb.jpg" alt="Pilot production of cement products" style="border-radius: 10px;">
                                </figure>
                            </div>
                            <div class="tile is-child" style="max-width: 300px; height: auto;" data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
                                <figure class="image rotate-90 is-1by1">
                                <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726059821/ugqyiclkdii3cglyjyj4.jpg" alt="Pilot production of cement products" style="border-radius: 10px;">
                                </figure>
                            </div>
                        </div>
                    </div>

                </div>

            </div>




        </div>
    </div>
</section>

<section class="hero is-medium">
  <div class="hero-body">
    <div class="container">
        <h1 class="title is-2 has-text-center has-text-black" style="font-size: 38px;" data-aos="fade-up" data-aos-delay="" data-aos-once="true">
            Setting up a Pilot Production Plant in Rubanda District <br>
            Ware House 
        </h1>
      <div class="columns is-vcentered">
        <!-- First column with the image -->
        <div class="column is-flex is-justify-content-center">
          <figure class="image" style="width: auto; height: auto; object-fit: cover;" data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
            <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726059823/i8urxllnkwkkyb5jwndg.jpg" alt="Kigezi cement image" style="border-radius: 10px;">
          </figure>
        </div>

        <!-- Second column with title and paragraphs -->
        <div class="column content">
            <figure class="image is-1by1" data-aos="fade-up" data-aos-delay="150" data-aos-once="true">
            <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726059824/ahzyeqtawjupinqk6l0q.jpg" alt="Pilot production of cement products" style="border-radius: 10px;">
          </figure>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="hero is-fullheight is-light">
  <div class="hero-body">
    <div class="container">
        <h1 class="title is-2 has-text-center has-text-black" style="font-size: 38px;" data-aos="fade-up" data-aos-delay="" data-aos-once="true">
            Value proposition
        </h1>
      <div class="columns is-vcentered">
          <!-- Second column with title and paragraphs -->
          <div class="column" data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
            <div class="content has-text-left">
                <li class="mb-5 custom-font-size">
                    A single concrete block has about 7% cement content which accounts for 40% of the total production cost.
                </li>
                <li class="mb-5 custom-font-size">
                    The cost of GPC as determined from our financial feasibility is 40% cheaper than OPC.
                </li>
                <li class="mb-5 custom-font-size">
                    By using GPC in blocks, the production cost is reduced by 17% providing a cheaper yet stronger and greener alternative.
                </li>
            </div>
          </div>
        <!-- First column with the image -->
        <div class="column is-flex is-justify-content-center">
          <figure class="image" style="width: px; height: px; object-fit: cover;" data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
            <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726059828/x2wjejy4ddrdpq3llsui.jpg" alt="Value proposition" style="border-radius: 10px;">
          </figure>
        </div>

      </div>
    </div>
  </div>
</section>

<section class="hero is-fullheight">
  <div class="hero-body">
    <div class="container">
        <h1 class="title is-2 has-text-center has-text-black" style="font-size: 38px;" data-aos="fade-up" data-aos-delay="" data-aos-once="true">
            Sustainable concrete products for Climate Resilient Rural Homesteads
        </h1>
        <p class="mb-5 custom-font-size has-text-left" data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
            As a part of the kigezi cement pilot production plant, Eco Concrete Ltd intends to construct a climate resilient demonstration building using kigezi cement products. This will be the first in a series of low cost net zero housing products designed specifically for rural homesteads. 
        </p>
      <div class="columns is-vcentered">
          <!-- Second column with title and paragraphs -->
          <div class="column is-flex is-justify-content-center">
            <figure class="image" style="width: px; height: px; object-fit: cover;" data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
            <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726059831/wpapheyt56ixkvjy45el.jpg" alt="demo house render" style="border-radius: 10px;">
          </figure>
          </div>
        <!-- First column with the image -->
        <div class="column is-flex is-justify-content-center">
          <figure class="image" style="width: px; height: px; object-fit: cover;" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
            <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726059829/hav8urmclhbwmxxjaxsi.jpg" alt="demo house plan" style="border-radius: 10px;">
          </figure>
        </div>

      </div>
    </div>
  </div>
</section>
<section class="hero is-fullheight is-light">
  <div class="hero-body">
    <div class="container">
        <h1 class="title is-2 has-text-center has-text-black" style="font-size: 38px;" data-aos="fade-up" data-aos-delay="" data-aos-once="true">
            Demonstration structure design
        </h1>
        <p class="mb-5 custom-font-size has-text-left" data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
            Consideration will be given to components of rural homestead infrastructure that promote productivity, quality,  and sustainability of agricultural activities at a homestead level, and their integration with the community for efficient utilization of resources and access to better markets.
        </p>
        <div class="is-flex is-justify-content-center">
            <figure class="image is16by9" data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
            <img src="https://res.cloudinary.com/celestialmk/image/upload/v1726059830/fpcxg6m11ngsiopaouf3.jpg" alt="demo house storage render" style="border-radius: 10px;">
          </figure>
        </div>
    </div>
  </div>
</section>

<section class="hero is-fullheight">
  <div class="hero-body">
    <div class="container">
        <h1 class="title is-2 has-text-center has-text-black" style="font-size: 38px;" data-aos="fade-up" data-aos-delay="" data-aos-once="true">
            Call for partnerships
        </h1>
        <p class="mb-5 custom-font-size has-text-left" data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
            Eco Concrete Limited calls for partnerships from manufacturers of construction materials to sponsor these items for the construction of low-cost net-zero  demonstration house. 
        </p>
        <div class="table-container is-flex is-justify-content-center">
            <table class="table is-striped has-text-left" data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Description</th>
                        <th>Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Timber Columns</td>
                        <td>Four- inch timber columns to support roof over shade</td>
                        <td>4 (No)</td>
                    </tr>
                    <tr>
                        <td>T 10 steel bars</td>
                        <td>T 10 steel bars in main ring beam reinforcement</td>
                        <td>25 (Pcs)</td>
                    </tr>
                    <tr>
                        <td>T 8 steel bars 
                        </td>
                        <td>T 8 steel bars in ring beam shear links
                        </td>
                        <td>20 (Pcs)</td>
                    </tr>
                    <tr>
                        <td>Iron sheets
                        </td>
                        <td>Iron sheets for roof covering
                        </td>
                        <td>60 (Pcs)</td>
                    </tr>
                    <tr>
                        <td>Sand 
                        </td>
                        <td>Sand for paving and finishing works
                        </td>
                        <td>40 (tons)</td>
                    </tr>
                    <tr>
                        <td>Internal doors
                        </td>
                        <td>2.1 X0.8m Hinged wooden paneled door
                        </td>
                        <td>7  (No)
                        </td>
                    </tr>
                    <tr>
                        <td>External doors
                        </td>
                        <td>2.4m x2.4m Hinged wooden door with glass panels
                        </td>
                        <td>1  (No)</td>
                    </tr>
                    <tr>
                        <td>Windows
                        </td>
                        <td>1.5m X 1.5m Steel casement window with glass panels
                        </td>
                        <td>5  (No)
                        </td>
                    </tr>
                    <tr>
                        <td>Ceiling boards
                        </td>
                        <td>4ft x 8ft wood/ gypsum ceiling boards
                        </td>
                        <td>30 (Pcs)</td>
                    </tr>
                    <tr>
                        <td>4X2 Timber 
                        </td>
                        <td>Timber in ceiling and roofing works
                        </td>
                        <td>156 (Pcs)</td>
                    </tr>
                    <tr>
                        <td>Paint
                        </td>
                        <td>Paint for total wall area  (450 sqm)
                        </td>
                        <td>450 (sqm)
                        </td>
                    </tr>
                    <tr>
                        <td>Tiles
                        </td>
                        <td>Tiles for Total floor area
                        </td>
                        <td>70 (sqm)
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</section>

</template>

<style scoped>
#hero {
    background-image: url('');
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    
}

.custom-font-size {
    font-size: 20px;
  }

  @media screen and (max-width: 768px) {
    .is-mobile-order-1 {
      order: 1;
    }
    .is-mobile-order-2 {
      order: 2;
    }
  }

  @media screen and (min-width: 769px) {
    .rotate-90 {
  transform: rotate(90deg);
}
    .my-image img {
      min-width: 500px; /* Example of styles applied on tablet and above */
      border-radius: 10px; /* Rounded corners only on tablet and larger */
    }
  }


</style>
<script>

</script>